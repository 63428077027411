import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/push",
    name: "Push",
    component: () => import("../views/Push.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/sales-control",
    name: "SalesControl",
    component: () => import("../views/SalesControl.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/customer",
    name: "Customer",
    component: () => import("../views/Customer.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/upload",
    name: "Upload",
    component: () =>
      import(/* webpackChunkName: "upload" */ "../views/Upload.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: { header: false }
  },
  {
    path: "/cart",
    name: "Cart",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Cart.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/print",
    name: "print",
    component: () =>
      import(/* webpackChunkName: "print" */ "../views/print.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/turnover",
    name: "Turnover",
    component: () =>
      import(/* webpackChunkName: "turnover" */ "../views/Turnover.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/shipment",
    name: "Shipment",
    component: () =>
      import(/* webpackChunkName: "shipment" */ "../views/Shipment.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/shipment/list",
    name: "ShipmentsList",
    component: () =>
      import(/* webpackChunkName: "shipment" */ "../views/ShipmentsList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/configuration/work-station",
    name: "WorkStationConfiguration",
    component: () => import("../views/WorkStationConfiguration.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/configuration/printer",
    name: "PrinterConfiguration",
    component: () => import("../views/PrinterConfiguration.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/list-manager",
    name: "ListManager",
    component: () =>
      import(/* webpackChunkName: "list-manager" */ "../views/ListManager.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/list-manager/upload",
    name: "ListManagerUpload",
    component: () =>
      import(
        /* webpackChunkName: "list-manager" */ "../views/ListManagerUpload.vue"
      ),
    meta: { requiresAuth: true }
  },
  {
    path: "/list-manager/configuration/:brandId",
    name: "ListManagerConfiguration",
    component: () =>
      import(
        /* webpackChunkName: "list-manager" */ "../views/ListManagerConfiguration.vue"
      ),
    meta: { requiresAuth: true }
  },
  {
    path: "/raxo",
    name: "Raxo",
    component: () => import("../views/Raxo.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/embed-page",
    name: "EmbedPage",
    component: () => import("../views/EmbedPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/supply-manager",
    name: "SupplyManager",
    component: () => import("../views/SupplyManager.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/price-control",
    name: "PriceControl",
    component: () => import("../views/PriceControl.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "*",
    meta: { customHeader: true },
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound")
  },
  {
    path: "/sales",
    name: "Sales",
    component: () => import("../views/Sales.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/products/*/:categoryId?/:brandName?/:brandId?",
    name: "Products",
    component: () => import("../views/Products.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/product/:itemId",
    name: "Product",
    component: () => import("../views/Product.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/stocks",
    name: "Stocks",
    component: () => import("../views/Stocks.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/template-preview",
    name: "TemplatePreview",
    component: () => import("../views/TemplatePreview.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/newsletters",
    name: "Newsletters",
    component: () => import("../views/Newsletters.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/newsletter/:newsletterId?",
    name: "Newsletter",
    component: () => import("../views/Newsletter.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/report",
    name: "Report",
    component: () => import("../views/Report.vue"),
    meta: { requiresAuth: true }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.header === false)) {
    store.commit("SET_HEADER", false);
  } else {
    store.commit("SET_HEADER", true);
  }

  const loggedIn = localStorage.getItem("token");
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    window.previousUrl = to.path;
    next("/login");
  }

  next();
});

export default router;
